<template>
  <div>

    <el-container>
      <el-header style="background-color:#FFF;">
        <el-row class="pt-2 pb-2">
          <el-col :span="20">
          <h3 style="line-height:1.8">Einstellungen</h3>
          </el-col>
          <el-col :span="2" style="line-height:50px; text-align:right">
            <el-button @click="$router.push({ name: 'dashboard' } )" class="mr-3 pl-3 pr-3 pt-2 pb-2" type="warning" icon="el-icon-s-home" ></el-button>
          </el-col>
          <el-col :span="2" class="text-right align-content-end">
            <el-image style="height:45px;" :src="require(`./../assets/dacy.png`)" fit="contain"></el-image>
          </el-col>
        </el-row>
      </el-header>
      <div class="w-100" style="background-color:#f39200; height:8px;"></div>
      <el-main>
        <el-row>
          <el-col :span="24">

            <el-card>

              <el-tabs type="card" tab-position="left">
                <el-tab-pane label="Standorte">
                  <setting-subsidiary></setting-subsidiary>
                </el-tab-pane>
                <el-tab-pane label="Behandler">
                  <setting-practitioner></setting-practitioner>
                </el-tab-pane>
                <el-tab-pane label="Diagnostiker">
                  <setting-doctor></setting-doctor>
                </el-tab-pane>
                <el-tab-pane label="Klinische Einheiten">
                  <setting-therapy-unit></setting-therapy-unit>
                </el-tab-pane>
                <el-tab-pane label="Behandlungsorte">
                  <setting-therapy-location></setting-therapy-location>
                </el-tab-pane>
                <el-tab-pane label="Tumorzentren">
                  <setting-tumor-center></setting-tumor-center>
                </el-tab-pane>
                <el-tab-pane label="Protokoll-Hausliste">
                  <setting-therapy-protocol-own-list></setting-therapy-protocol-own-list>
                </el-tab-pane>
                <el-tab-pane label="Export">
                  <setting-export></setting-export>
                </el-tab-pane>
                <el-tab-pane label="Import">
                  <setting-import></setting-import>
                </el-tab-pane>
                <el-tab-pane label="Synchronisation">
                  <setting-sync></setting-sync>
                </el-tab-pane>
                <el-tab-pane label="DACY-Benutzer">
                  <setting-user></setting-user>
                </el-tab-pane>
                <el-tab-pane label="Karteikartentypen">
                  <setting-file-card-type></setting-file-card-type>
                </el-tab-pane>
                <el-tab-pane label="Krebsregister">
                  <setting-cancer-registry></setting-cancer-registry>
                </el-tab-pane>
              </el-tabs>

            </el-card>

          </el-col>
        </el-row>
      </el-main>
    </el-container>

  </div></template>

<script>
import SettingDoctor from "@/components/setting/SettingDoctor";
import SettingTherapyUnit from "@/components/setting/SettingTherapyUnit";
import SettingPractitioner from "@/components/setting/SettingPractitioner";
import SettingTherapyLocation from "@/components/setting/SettingTherapyLocation";
import SettingTumorCenter from "@/components/setting/SettingTumorCenter";
import SettingExport from "@/components/setting/SettingExport";
import SettingImport from "@/components/setting/SettingImport";
import SettingSync from "@/components/setting/SettingSync";
import SettingSubsidiary from "@/components/setting/SettingSubsidiary";
import SettingUser from "@/components/setting/SettingUser";
import SettingTherapyProtocolOwnList from "@/components/setting/SettingTherapyProtocolOwnList";
import SettingFileCardType from "@/components/setting/SettingFileCardType";
import SettingCancerRegistry from "@/components/setting/SettingCancerRegistry";

export default {
  name: "Setting",
  props: [],
  components: {
    SettingTumorCenter,
    SettingPractitioner,
    SettingTherapyUnit,
    SettingDoctor,
    SettingTherapyLocation,
    SettingExport,
    SettingImport,
    SettingSync,
    SettingSubsidiary,
    SettingUser,
    SettingTherapyProtocolOwnList,
    SettingFileCardType,
    SettingCancerRegistry
  },
  data () {
    return {
    }
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
}

}
</script>
<style scoped>
</style>
